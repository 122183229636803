// extracted by mini-css-extract-plugin
export var header = "T_H";
export var item = "T_bJ";
export var itemImage = "T_cT";
export var itemIsExternal = "T_cQ";
export var itemIsInView = "T_cN";
export var itemIsLinked = "T_cP";
export var itemText = "T_cV";
export var itemTime = "T_cR";
export var itemTimeIsBig = "T_cS";
export var logo = "T_bS";
export var root = "T_b";